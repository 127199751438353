<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>新闻中心</h3>
              <p>NEWS</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner on">
                <a :href="`${apiName}list`">公司要闻</a>
                <span></span>
              </div>
              <div class="listSlidListInner">
                <a :href="`${apiName}list?t=1`">项目动态</a>
                <span></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd clearfloat">
              <div class="fl listHdName">新闻中心</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}list`">新闻中心</a> · <span class="txt">公司要闻</span> </div>
            </div>
            <div class="listContainer">
              <ul class="listList">
                <li v-for="(item, index) in listData" :key="index">
                  <a class="clearfloat" :href="`${apiName}detail`">
                    <div class="time fr">
                      <h3>{{item.time.slice(5,10)}}</h3>
                      <p>{{item.time.slice(0,5)}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                </li>
              </ul>
              <div class="listPage">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange">
                </el-pagination>
              </div>
              
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  data(){
    return {
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: [
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
      ]
    }
  },
  mounted(){

  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    }
  }
}
</script>